import { createFeature, createReducer, on } from '@ngrx/store';
import { Consumer } from 'src/app/shared/models';
import { ConsumerActions } from './consumer.actions';

export const consumerFeatureKey = 'consumer';

export interface ConsumerState {
  consumers: Consumer[];
  selectedConsumer: Consumer | null;
  loading: boolean;
  error: any;
}

export const initialState: ConsumerState = {
  consumers: [],
  selectedConsumer: null,
  loading: false,
  error: null,
};

export const reducer = createReducer(
  initialState,
  on(
    ConsumerActions.loadConsumers,
    (state): ConsumerState => ({
      ...state,
      loading: true,
      error: null,
    })
  ),
  on(
    ConsumerActions.loadConsumersSuccess,
    (state, { consumers }): ConsumerState => ({
      ...state,
      consumers,
      loading: false,
      error: null,
    })
  ),
  on(
    ConsumerActions.loadConsumersFailure,
    (state, { error }): ConsumerState => ({
      ...state,
      loading: false,
      error,
    })
  ),
  on(
    ConsumerActions.loadConsumer,
    (state): ConsumerState => ({
      ...state,
      loading: true,
      error: null,
    })
  ),
  on(
    ConsumerActions.loadConsumerSuccess,
    (state, { consumer }): ConsumerState => ({
      ...state,
      consumers: [consumer],
      selectedConsumer: consumer,
      loading: false,
      error: null,
    })
  ),
  on(
    ConsumerActions.loadConsumerFailure,
    (state, { error }): ConsumerState => ({
      ...state,
      loading: false,
      error,
    })
  ),
  on(
    ConsumerActions.addConsumer,
    (state): ConsumerState => ({
      ...state,
      loading: true,
      error: null,
    })
  ),
  on(
    ConsumerActions.addConsumerSuccess,
    (state, { consumer }): ConsumerState => ({
      ...state,

      consumers: [...state.consumers, consumer],

      loading: false,

      error: null,
    })
  ),
  on(
    ConsumerActions.addConsumerFailure,
    (state, { error }): ConsumerState => ({
      ...state,
      loading: false,
      error,
    })
  ),
  on(
    ConsumerActions.removeConsumer,
    (state): ConsumerState => ({
      ...state,
      loading: true,
      error: null,
    })
  ),
  on(
    ConsumerActions.removeConsumerSuccess,
    (state, { consumerId }): ConsumerState => ({
      ...state,
      consumers: state.consumers.filter(consumer => consumer.id !== consumerId),
      loading: false,
      error: null,
    })
  ),
  on(
    ConsumerActions.removeConsumerFailure,
    (state, { error }): ConsumerState => ({
      ...state,
      loading: false,
      error,
    })
  ),
  on(
    ConsumerActions.updateConsumer,
    (state): ConsumerState => ({
      ...state,
      loading: true,
      error: null,
    })
  ),
  on(
    ConsumerActions.updateConsumerSuccess,
    (state, { consumer }): ConsumerState => ({
      ...state,
      consumers: state.consumers.map(item =>
        // item.id === consumer.id ? consumer : item
        item.id === consumer.id ? { ...item, ...consumer } : item
      ),
      loading: false,
      error: null,
    })
  ),
  on(
    ConsumerActions.updateConsumerFailure,
    (state, { error }): ConsumerState => ({
      ...state,
      loading: false,
      error,
    })
  )
);

export const consumerFeature = createFeature({
  name: consumerFeatureKey,
  reducer,
});
